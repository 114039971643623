<template>
  <v-container fluid class="pr-0">
    <v-card flat :class="$vuetify.breakpoint.mobile ? '' : 'pa-2'">  
      <v-form lazy-validation v-on:submit.prevent="gravarRespostasFormulario" ref="form">    
        <v-row justify="center">
          <v-card width="100%" min-width="200" flat>
            <v-row no-gutters>
              <template v-for="(pergunta, i) in perguntas">
                <v-col
                  :cols="pergunta.config.cols" 
                  :md="pergunta.config.md"
                  :align-self="pergunta.config.align"
                  :offset="pergunta.config.offset"
                  :key="i"
                >
                  <component
                    :is="pergunta.tipoPergunta.nomeComponent"
                    :key="`component-${pergunta.tipoPergunta.code.toLowerCase()}`"
                    v-bind="{ ...pergunta.props }"
                    @returValue="valorPergunta(pergunta, $event)"
                  ></component>
                </v-col>
              </template>
            </v-row>
          </v-card>
        </v-row>     
        <alerta></alerta>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import Alerta from "@/components/comum/Alerta.vue";
import { mapActions, mapGetters } from "vuex";
import { postFileStorage, deleteFileStorage } from "@/utils/storageUtils"

import RespostaCurtaCreateEdit from "@/components/forms/RespostaCurtaCreateEdit.vue";
import RespostaTextoCreateEdit from "@/components/forms/RespostaTextoCreateEdit.vue";
import RespostaVerdadeiroFalsoCreateEdit from "@/components/forms/RespostaVerdadeiroFalsoCreateEdit.vue";
import RespostaDataCreateEdit from "@/components/forms/RespostaDataCreateEdit.vue";
import RespostaUnicaCreateEdit from "@/components/forms/RespostaUnicaCreateEdit.vue";
import RespostaMultiplaCreateEdit from "@/components/forms/RespostaMultiplaCreateEdit.vue";
import RespostaAudioCreateEdit from "@/components/forms/RespostaAudioCreateEdit.vue";
import RespostaArquivoCreateEdit from "@/components/forms/RespostaArquivoCreateEdit.vue";
import RespostaImagemCreateEdit from "@/components/forms/RespostaImagemCreateEdit.vue";
import RespostaVideoCreateEdit from "@/components/forms/RespostaVideoCreateEdit.vue";
import RespostaSubtituloCreateEdit from "@/components/forms/RespostaSubtituloCreateEdit.vue";
import RespostaEditorCreateEdit from "@/components/forms/RespostaEditorCreateEdit.vue";
import RespostaLegendaCreateEdit from "@/components/forms/RespostaLegendaCreateEdit.vue";

export default {
  name: "formulario-view",
  props: {
    id: {
      type: String,
      default: undefined,
    },
    storageNameParam: {
      type: String,
      default: undefined,
    },
    domain: {
      type: String,
      default: undefined,
    },
    domainId: {
      type: Number,
      default: undefined,
    },
    formularioParam: {
      type: Object,
      default: undefined,
    },
    gravarFormulario: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return { 
      storageName: null,
      filaChamadasStorage: [],
      formulario: {},
      respostasFormulario: new Map(),
      showBtnEnviar: false,
      perguntas: [],
      localPerguntas: [],
      perguntasOriginais: [],
    };
  },
  components: {
    Alerta,
    RespostaCurtaCreateEdit,
    RespostaTextoCreateEdit,
    RespostaVerdadeiroFalsoCreateEdit,
    RespostaDataCreateEdit,
    RespostaUnicaCreateEdit,
    RespostaMultiplaCreateEdit,
    RespostaAudioCreateEdit,
    RespostaArquivoCreateEdit,
    RespostaImagemCreateEdit,
    RespostaVideoCreateEdit,
    RespostaSubtituloCreateEdit,
    RespostaEditorCreateEdit,
    RespostaLegendaCreateEdit,
  },
  computed: {
    ...mapGetters(["formularioQuery"]),
    tipoPerguntaMedia() {
      return ["VIDEO", "IMAGEM", "ARQUIVO", "AUDIO"]
    },
  },
  watch: {
    gravarFormulario (value) {
      if (value) {
        this.formulario.domain = this.domain
        this.formulario.domainId = this.domainId
        this.formulario.modeloFormulario = { _id: this.formulario.modeloFormulario ? this.formulario.modeloFormulario._id : this.formulario._id }

        this.gravarRespostasFormulario()
      }
    },
    filaChamadasStorage(newValue) {
      var self = this;
      if (newValue.length === 0) {

        // Verifica se houve arquivos de mídia excluído nas respostas e deleta no storage
        this.deleteFilesOnStorage(self.perguntasOriginais, self.perguntas)

        self.formulario.perguntas = [...self.localPerguntas];

        self.$http.put(`/forms/auditoria/${self.formulario._id}`, self.formulario)
          .then(() => {
            this.$emit("confirmSaveForms", true)
          })
          .catch(() => {
            self.$http.post(`/forms`, self.formulario)
              .then (() => {
                this.$emit("confirmSaveForms", true)
              })
              .catch (() => {
                this.$emit("confirmSaveForms", false)
                self.setAlerta({
                  tipoAlerta: "error",
                  textAlerta: "Erro na atualizalção do formulário",
                });
              })
          });
      }
    },
  },
  methods: {
    ...mapActions(["setUltimaTela", "setAlerta", "setFormularioQuery"]),
    deleteFilesOnStorage(perguntasOriginaisParam, perguntasAlteradasParam) {

      if(Array.isArray(perguntasOriginaisParam) && Array.isArray(perguntasAlteradasParam)) {

        let respostasAlteradas = []
        let respostasOriginais = []
        let respostasToDelete = []

        // Filtra somente as perguntas de mídia após alteração no formulário
        let perguntasAlteradas = perguntasAlteradasParam.map(p => ({...p}))
          .filter(p => (this.tipoPerguntaMedia.indexOf(p.tipoPergunta.code) > -1 && p.respostas != null && p.respostas.length > 0))
        
        // Filtra somente as perguntas de mídia antes alteração no formulário
        let perguntasOriginais = perguntasOriginaisParam.map(p => ({...p}))
          .filter(p => (this.tipoPerguntaMedia.indexOf(p.tipoPergunta.code) > -1 && p.respostas != null && p.respostas.length > 0))
        
        // Carrega as respostas resultantes das alterações realizadas no formulário
        if(perguntasAlteradas != null && perguntasAlteradas.length > 0) {
          respostasAlteradas = perguntasAlteradas.map(p => p.respostas).reduce((acc, val) => acc.concat(val), [])
        }

        // Carrega as respostas originais das alterações realizadas no formulário
        if(perguntasOriginais != null && perguntasOriginais.length > 0) {
          respostasOriginais = perguntasOriginais.map(p => p.respostas).reduce((acc, val) => acc.concat(val), [])
        }
        
        respostasToDelete = respostasOriginais.filter(r => !respostasAlteradas.map(r1 => r1.id).includes(r.id))
  
        respostasToDelete.forEach(r => {
          deleteFileStorage(this.storageName, r.valor.nomeArquivo).then(response => {
            console.log("Funcionou DELETE: \n", response);
          }). catch (error => {
            console.log("Não funcionou DELETE: \n", error);
          })
        })
      }
    },
    gravarRespostasFormulario() {
      try {
        var self = this;

        if(!this.$refs.form.validate()) {
          self.setAlerta({
            tipoAlerta: 'error',
            textAlerta: 'Favor preencher todos os campos obrigatórios'
          })
          return;
        }
        self.localPerguntas = Object.assign([], this.perguntas);
        self.filaChamadasStorage.push("waiting");
        self.localPerguntas.forEach((perg) => {
          perg.respostas = [];
          if (self.respostasFormulario.get(`${perg.ordem}`)){
            let respostaObject = self.respostasFormulario.get(`${perg.ordem}`).resposta;

            if (this.tipoPerguntaMedia.indexOf(perg.tipoPergunta.code) !== -1) {

              Object.assign([], respostaObject).forEach(
                (localValorResposta) => {
                  self.filaChamadasStorage.push("waiting");

                  if(!localValorResposta.valor.id) {
                    postFileStorage(localValorResposta.valor.file, this.storageName)
                      .then((response) => {
                        perg.respostas.push({
                          valor: JSON.stringify(response.data.arquivo),
                        });
                        self.filaChamadasStorage.pop();
                      })
                      .catch(() => {
                        console.log(">>>>>>>>>> deuErro");
                      });
                  } else {
                    localValorResposta.valor = JSON.stringify(localValorResposta.valor);
                    perg.respostas.push(localValorResposta)
                    self.filaChamadasStorage.pop();
                  }
                }
              );
            } else if (["UNICA_ESCOLHA", "MULTIPLA_ESCOLHA", "DATA", "CURTA", "TEXTO", "VERDADEIRO_FALSO", "EDITOR"].indexOf(perg.tipoPergunta.code) !== -1) {
              perg.respostas = respostaObject;
            } else {
              self.setAlerta({
                tipoAlerta: 'error',
                textAlerta: `Tipo de pergunta não registrado [${perg.tipoPergunta.code}]! Favor entrar em contato com o administrador!`
              })
            }
          }
          delete perg.props;
          delete perg.config;
        });
        self.filaChamadasStorage.pop();
      } catch (error) {
        Promise.reject(
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: error,
          })
        );
      }
    },
    valorPergunta(pPergunta, respostaPergunta) {
      this.respostasFormulario.set(`${pPergunta.ordem}`, {
        pergunta: pPergunta,
        resposta: respostaPergunta,
      });
    },
    defineComponentInstance(nomeComponent) {
      return nomeComponent.replace("forms/", "").replace("audio/", "");
    },
    prepareFormulario(response){

      // Função recursiva para transformação de string json em objeto javascript
      const jsonToObject = str => typeof str === "object" ?  str : jsonToObject(JSON.parse(str))
      
      var self = this;
      self.formulario = JSON.parse(JSON.stringify(response.data))
      
      self.storageName = self.storageNameParam;
      if (self.formulario.unidade && self.formulario.unidade.storage){
        self.storageName = self.formulario.unidade.storage;
      }
      
      if (!(self.storageName && self.storageName.length > 3)){       
        self.setAlerta({
          tipoAlerta: "error",
          textAlerta: "Por favor, contate o Administrador.\n \nA unidade não possui storage configurado!",
          timeoutAlerta: 20000
        });
        self.showBtnEnviar = false;
      }else{
        // Copia as perguntas e respostas originais para tratamento de exclusão dos arquivos do storage.
        self.perguntasOriginais = response.data.perguntas.map(p => ({...p}));
        self.perguntas = Object.assign([], response.data.perguntas).sort((a, b) => a.ordem - b.ordem);

        self.perguntas.forEach((p) => {
          // Tratamento para renderização dos componentes - transformação de respostas   
          switch(p.tipoPergunta.code) {
            case 'IMAGEM': 
              p.config = {cols: 12, md: p.sizeMd, align: "center", valor: null} 
              break;
            case 'VIDEO':
              p.config = {cols: 12, md: p.sizeMd, align: "center", valor: null}
              break;
            case 'ARQUIVO':
              p.config = {cols: 12, md: p.sizeMd, align: "center", valor: null}
              break;
            case 'AUDIO':
              p.config = {cols: 12, md: p.sizeMd, align: "center", valor: null}
              break;
            case 'CURTA': 
              p.config = {cols: 12, md: p.sizeMd, align: "center", valor: null}
              break;
            case 'TEXTO': 
              p.config = {cols: 12, md: p.sizeMd, align: "center", valor: null}
              break;
            case 'VERDADEIRO_FALSO': 
              p.config = {cols: 12, md: p.sizeMd, align: "center", valor: null}
              break;
            case 'DATA': 
              p.config = {cols: 12, md: p.sizeMd, align: "center", valor: null}
              break;
            case 'UNICA_ESCOLHA':
              p.config = {cols: 12, md: p.sizeMd, align: "center", valor: null}
              break;
            case 'MULTIPLA_ESCOLHA':
              p.config = {cols: 12, md: p.sizeMd, align: "center", valor: null}
              break;
            case 'EDITOR':
              p.config = {cols: 12, md: p.sizeMd, align: "center", valor: null}
              break;
            case 'LEGENDA':
              p.config = {cols: 12, md: p.sizeMd, align: "start", offset: "0", valor: null}
              break;
            default:
              p.config = {cols: 12, md: p.sizeMd, align: "center", valor: null}
          }

          // Renderiza o componente de acordo com o código
          p.tipoPergunta.nomeComponent = self.defineComponentInstance(p.tipoPergunta.nomeComponent);

          // Tratamento para definição do modelo de exibição [Hard Code - Inicial]
          p.showSelectionBoxes = p.modeloExibicao == "CS" ? true : false

          // Tratamento para visualização de respostas quando já existirem
          if(p.respostas != null && p.respostas.length > 0) {
            
            // Converte o valor das respostas para objeto
            let respostasObject = p.respostas.map(r => {
              r.valor = jsonToObject(r.valor)
              return r;
            });
            p.respostas = Object.assign(respostasObject);

            let perguntaValores = (p.perguntaValores && p.perguntaValores.length > 0) ? p.perguntaValores.sort((p1, p2) => {
              return p1.ordem - p2.ordem;
            }) : null

            // Tratamento para renderização dos componentes - transformação de respostas       
            switch(p.tipoPergunta.code) {
              case 'IMAGEM': 
                p.props = {labelTitle: p.descricao, items: perguntaValores, obrigatorio: p.obrigatorio, storageName: self.storageName, respostas: p.respostas}
                break;
              case 'VIDEO':
                p.props = {labelTitle: p.descricao, items: perguntaValores, obrigatorio: p.obrigatorio, storageName: self.storageName, respostas: p.respostas}
                break;
              case 'ARQUIVO':
                p.props = {labelTitle: p.descricao, items: perguntaValores, obrigatorio: p.obrigatorio, storageName: self.storageName, respostas: p.respostas}
                break;
              case 'AUDIO':
                p.props = {labelTitle: p.descricao, items: perguntaValores, obrigatorio: p.obrigatorio, storageName: self.storageName, respostas: p.respostas}
                break;
              case 'CURTA': 
                p.props = {labelTitle: p.descricao, items: perguntaValores, obrigatorio: p.obrigatorio, respostas: p.respostas}
                break;
              case 'TEXTO': 
                p.props = {labelTitle: p.descricao, items: perguntaValores, obrigatorio: p.obrigatorio, respostas: p.respostas}
                break;
              case 'VERDADEIRO_FALSO': 
                p.props = {labelTitle: p.descricao, items: perguntaValores, obrigatorio: p.obrigatorio, respostas: p.respostas}
                break;
              case 'DATA': 
                p.props = {labelTitle: p.descricao, items: perguntaValores, obrigatorio: p.obrigatorio, respostas: p.respostas}
                break;
              case 'UNICA_ESCOLHA':
                p.props = {labelTitle: p.descricao, items: perguntaValores, obrigatorio: p.obrigatorio, showSelectionBoxes: p.showSelectionBoxes, respostas: p.respostas}
                break;
              case 'MULTIPLA_ESCOLHA':
                p.props = {labelTitle: p.descricao, items: perguntaValores, obrigatorio: p.obrigatorio, showSelectionBoxes: p.showSelectionBoxes, respostas: p.respostas}
                break;
              case 'EDITOR':
                p.props = {labelTitle: p.descricao, items: perguntaValores, obrigatorio: p.obrigatorio, showSelectionBoxes: p.showSelectionBoxes, respostas: p.respostas}
                break;
              case 'LEGENDA':
                p.props = {labelTitle: p.descricao, items: perguntaValores, obrigatorio: p.obrigatorio, showSelectionBoxes: p.showSelectionBoxes, respostas: p.respostas}
                break;
              default:
                p.props = {labelTitle: p.descricao}
            }

          } else {
            let perguntaValores = (p.perguntaValores && p.perguntaValores.length > 0) ? p.perguntaValores.sort((p1, p2) => {
              return p1.ordem - p2.ordem;
            }) : null
            p.props = { labelTitle: p.descricao, items: perguntaValores, obrigatorio: p.obrigatorio, showSelectionBoxes: p.showSelectionBoxes };
          }
        });
      }
    },
    loadFormulario(url) {
      var self = this;

      this.$http
        .get(url)
        .then((response) => {
          self.prepareFormulario(response);
        })
        .catch((e) => {
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: !e.response ? "Connection Refused!" : e.response.data.message,
          })
        });
    },
    async loadDynamicForm(){

      let response = {
        status: 200,
        message: "OK",
        data: {}
      }

      if (this.formularioParam) {
        response = {...response, data: this.formularioParam}
      } else {
        response = await this.$http.get(`/forms/${this.id}`).catch(() => {
          this.loadFormulario (`/modeloForms/${this.id}`);
        })
      }
      
      if (response) this.prepareFormulario (response)
    },
  },
  created() {
    this.loadDynamicForm();
  },
  mounted() {},
};
</script>
