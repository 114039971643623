<template>
  <v-container class="ma-0 pa-0">
    <v-card elevation="1" min-height="205">
      <v-data-table 
        v-model="atendimentoSelecionado"
        :items="atendimentos"
        :headers="headers"
        :options="{ itemsPerPage: 1000 }"
        hide-default-footer
        :loading="loading"
        height="205"
        @click:row="openAtendimentoDialog"
        disable-sort
        fixed-header
      >
        <template v-slot:[`item.registroPreAtendimento.agendamento.dataConsulta`]="{ item }">
          <span> {{ new Date(item.registroPreAtendimento.agendamento.dataConsulta).toLocaleString('pt-BR') }} </span>
        </template>
      </v-data-table>
      <alerta></alerta>
    </v-card>

    <v-dialog 
      v-model="dialog"
      scrollable
      transition="dialog-bottom-transition"
    >
      <v-card flat>
        <v-toolbar dense dark color="primary" max-height="50">
          <v-toolbar-title>{{ tituloAtendimento }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn small outlined class="mx-2" :href="openAtendimentoLink(atendimentoShow.id)" target="_blank">
            <span>Abrir Atendimento</span>
          </v-btn>
          <v-btn icon @click.native="dialog = false" dark>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text v-if="atendimentoShow.formularioAtendimento && atendimentoShow.formularioAtendimento._id">
          <FormularioView
            :key="`${atendimentoShow.formularioAtendimento._id}`"
            :formularioParam="atendimentoShow.formularioAtendimento"
            :storageNameParam="atendimentoShow.registroPreAtendimento.agendamento.unidade.storage"
          ></FormularioView>
        </v-card-text>
        <v-card-text v-else>
          <v-card-title class="pb-1">Título</v-card-title>
          <v-text-field
            v-model="atendimentoShow.titulo"
            readonly
            rounded
            filled
          ></v-text-field>
          <v-row>
            <v-card-title class="pb-1">Relatório de consulta</v-card-title>
            <v-col cols="12">
              <div class="mb-4 conteudo-atendimento-html">
                <div class="pa-4 my-2" v-html="atendimentoShow.conteudo"></div>
              </div>
            </v-col>
          </v-row>
          <v-row v-if="isAtendimentoAssinado" justify="center" class="my-0">
            <v-chip small outlined color="red" text-color="default">
              <span v-text="mensagemAtendimentoAssinado"></span>
            </v-chip>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Alerta from "@/components/comum/Alerta.vue";
import { mapActions } from "vuex";
import moment from 'moment'
import FormularioView from "@/components/forms/FormularioView.vue";

export default {
  name: "atendimento-paciente-list",
  props: {
    cpfPaciente: {
      type: String,
      default: null,
      required: true
    },
    atendimentoId: {
      default: null,
      required: true
    }
  },
  components: {
    Alerta,
    FormularioView
  },
  data () {
    return {
      loading: true,
      atendimentos: [],
      atendimentoSelecionado: [],
      headers: [
        {
          text: "Data Consulta",
          align: "center",
          sortable: true,
          value: "registroPreAtendimento.agendamento.dataConsulta",
        },
        {
          text: "Tipo Consulta",
          align: "center",
          sortable: true,
          value: "registroPreAtendimento.agendamento.tipoConsulta.nome",
        },
        {
          text: "Unidade",
          align: "center",
          sortable: true,
          value: "registroPreAtendimento.agendamento.unidade.nome",
        },
      ],
      dialog: false,
      atendimentoShow: {},
    }
  },
  watch: {
    cpfPaciente () {
      if (this.cpfPaciente && this.atendimentoId) {
        this.loadModel();
      }
    }
  },
  computed: {
    tituloAtendimento () {
      return this.atendimentoShow.id ? this.atendimentoShow.registroPreAtendimento.agendamento.paciente.nome : ""
    },
    isAtendimentoAssinado() {
      if(this.atendimentoShow.dataAssinatura && this.atendimentoShow.usuarioAssinatura) {
        return true;
      } 
      return false;
    },
    mensagemAtendimentoAssinado() {
      let dataAssinatura = moment(this.atendimentoShow.dataAssinatura, "YYYY-MM-DDTHH:mm:ss").format("DD/MM/YYYY HH:mm:ss");
      let mensagem = "Assinado por Dr(a) " + this.atendimentoShow.usuarioAssinatura.nomeExibicao + " em " + dataAssinatura;

      return mensagem;
    },
  },
  methods: {
    ...mapActions(["setAlerta"]),
    loadModel () {

      if(!this.cpfPaciente) {
        self.loading = !self.loading
        return
      }

      const self = this;
      let url = `/atendimento?max=99999&cpfPaciente=${self.cpfPaciente}`
      // BUSTA TODOS OS ATENDIMENTOS NO PERÍODO
      url += `&dataInicial=1970-01-01&dataFinal=2050-12-31`
      // BUSCA SOMENTE ATENDIDOS
      url += `&status=2`

      self.$http
        .get(url)
        .then((response) => {
          self.loading = !self.loading
          // RETORNA TODOS OS ATENDIMENTOS EXCETO O ATENDIMENTO CORRENTE
          self.atendimentos = response.data.content
            .filter(a => a.id != self.atendimentoId)
            .sort((a, b) => b.registroPreAtendimento.agendamento.dataConsulta - a.registroPreAtendimento.agendamento.dataConsulta);
        })
        .catch(() => {
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: "Erro ao buscar atendimentos anteriores",
          });
          self.loading = !self.loading
        });
    },
    async openAtendimentoDialog (value) {
      this.atendimentoShow = await this.getAtendimentoById (value.id)

      let nomeTipoFormulario = `Relatório Médico`
      await this.setFormularioByAtendimento(this.atendimentoShow, nomeTipoFormulario)
      
      this.dialog=!this.dialog
    },
    async getAtendimentoById (id) {   
      let atendimento =  await this.$http.get(`/atendimento/${id}`)
      return atendimento.data
    },
    openAtendimentoLink (id) {
      return `${process.env.VUE_APP_MEZZOW_WEB_CLIENT_URL}atendimento/edit/${id}`
    },
    async setFormularioByAtendimento(atendimento, nomeTipoFormulario) {
      const self = this;

      // Retorna caso não exita atendimentos
      if (!atendimento || !atendimento.id || !nomeTipoFormulario) return

      // Busca o id do tipo formulario
      let tipoFormularioId = await self.getTipoFormularioIdByNome(nomeTipoFormulario)
      if (!tipoFormularioId) return

      let domain = `com.mezzow.domain.Atendimento`
      let domainId = atendimento.id
      let formulariosAtendimento = await self.$http.get(`/forms?tipoFormularioId=${tipoFormularioId}&domain=${domain}&domainId=${domainId}&situacao=true&order=desc`).then(response => response.data.content)
      if (formulariosAtendimento && formulariosAtendimento.length > 0) {
        atendimento.formularioAtendimento = formulariosAtendimento[0]
      }
    },
    async getTipoFormularioIdByNome(nomeTipoFormulario) {
      const self = this
      if (nomeTipoFormulario) {
        let tiposFormularios = await self.$http.get(`/tipoForms?nome=${nomeTipoFormulario}&situacao=true`).then(response => response.data.content)
       
        if (tiposFormularios && tiposFormularios.length > 0) {
          return tiposFormularios[0]._id
        }
      }
    },
  },
};
</script>

<style>
.conteudo-atendimento-html {
  border-radius: 28px; 
  border-color: currentColor;
  border-style: solid;
  border-width: 1px;
}
</style>